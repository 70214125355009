<template>
  <div class="row d-flex flex-row-fluid">
    <!--begin::SIDERBAR-->
    <div class="flex-row-fluid col-lg-3">
      <!--begin::NAV MENU-->
      <div class="card card-custom mb-5">
        <div class="card-header">
          <div class="card-title"><h3 class="card-label">Menu de operações</h3></div>
        </div>
        <!--begin::Body-->
        <div class="card-body pt-4">
          <!--begin::Nav-->
          <Sidebar :items="itemsSidebar" variant="success" />
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::NAV MENU-->
      <!--end::WALLET Card-->
      <WalletWidget :card-stretch="false" wallet-type="real" />
      <!--end::WALLET Card-->
    </div>
    <!--end::SIDERBAR-->

    <!--begin::CONTEUDO-->
    <router-view />
    <!--end::CONTEUDO-->
  </div>
</template>

<script>
import WalletWidget from '@/shared/components/wallet/WalletWidget';
import Sidebar from '@/shared/layout/aside/Sidebar';

export default {
  components: {
    Sidebar,
    WalletWidget
  },
  data() {
    return {
      itemsSidebar: [
        {
          label: 'Extrato',
          icon: '/media/svg/icons/Layout/Layout-left-panel-2.svg',
          href: '/financial/real/extract',
          parents: '/financial/real/extract'
        },
        {
          label: 'Transferência',
          icon: '/media/svg/icons/Navigation/Up-down.svg',
          href: '/financial/real/transfer',
          parents: '/financial/real/transfer'
        },
        {
          label: 'Depósitos',
          icon: '/media/svg/icons/Shopping/Safe.svg',
          href: '/financial/real/deposit',
          parents: '/financial/real/deposit'
        },
        {
          label: 'Saques',
          icon: '/media/svg/icons/Communication/Share.svg',
          href: '/financial/real/withdraw',
          parents: '/financial/real/withdraw'
        },
        {
          label: 'Pagamentos',
          icon: '/media/svg/icons/Shopping/ATM.svg',
          href: '/profile/address',
          parents: '/profile/address'
        }
      ]
    };
  }
};
</script>

<style></style>
